import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', () => {
    
    const user = ref({})
    const user_loading = ref(true)
    const is_authenticated = ref(false)

    const fetchUser = async () => {

        user_loading.value = true
        return useApi().user.me()
            .then( (res) => {
                user.value = res.data
                is_authenticated.value = true
            })
            .catch( () => is_authenticated.value = false )
            .finally( () => user_loading.value = false )
    }


    return { 
        user,
        user_loading,
        is_authenticated, 
        fetchUser,
    }

})
